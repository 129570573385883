<template>
  <div>
    <nav class="cos-breadcrumb text-sm text-stone-700" aria-label="Breadcrumbs">
      <ul class="flex flex-wrap">
        <li v-for="(matched, index) in breadcrumbs" :key="index" class="mb-2">
          <span v-if="matched && (index !== breadcrumbs.length - 1)">
            <NuxtLink :href="matched.link">{{ matched.label }}</NuxtLink>
          </span>
          <span v-else class="italic">{{ matched.label }}</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const routes = useRouter().getRoutes()
const pathSegments = route.path.split('/').filter((segment: string) => segment !== '')
const breadcrumbs: any = []

let tmpPath = ''

for (const pathSegment of pathSegments) {
  tmpPath += '/' + pathSegment

  const result = routes.find((x: { path: string; }) => x.path === tmpPath)
  if (result === undefined) { continue }

  breadcrumbs.push({
    label: result.name,
    link: result.path + '/',
  })
}

if (breadcrumbs.length === 1) { breadcrumbs.length = 0 }
</script>
